// remap jQuery to $
(function($)
{

    var ua = navigator.userAgent,
        isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
    if (isMobileWebkit) {
        $('html').addClass('mobile');

        // FastClick.js removing 300ms delay on iOS
        document.addEventListener('load', function()
        {
            new FastClick('body');
        }, false);

        /* cache dom references */
        var $body = $('body');

        /* bind events */
        $(document)
        .on('focus', 'input', function(e)
        {
            $body.addClass('fixfixed');
        })
        .on('blur', 'input', function(e)
        {
            $body.removeClass('fixfixed');
        });
    }

    /* trigger when page is ready */
    $(document).ready(function()
    {

        // On Scroll Animated Header - adapted from http://tympanus.net/codrops/2013/06/06/on-scroll-animated-header/	
        var cbpAnimatedHeader = (function()
        {
            var docElem = document.documentElement,
                header = document.querySelector('#header'),
                didScroll = false,
                changeHeaderOn = 232;

            function init()
            {
                window.addEventListener('scroll', function(event)
                {
                    if (!didScroll) {
                        didScroll = true;
                        setTimeout(scrollPage, 0);
                    }
                }, false);
            }

            function scrollPage()
            {
                var sy = scrollY();
                if (sy >= changeHeaderOn) {
                    $(header).addClass('header-shrink');
                    $('#join,#donate').addClass('form-shrink')
                    $('#nav .block').addClass('block-shrink');
                }
                else {
                    $(header).removeClass('header-shrink');
                    $('#join,#donate').removeClass('form-shrink')
                    $('#nav .block').removeClass('block-shrink');
                }
                didScroll = false;
            }

            function scrollY()
            {
                return window.pageYOffset || docElem.scrollTop;
            }

            init();
        })();

        // Main Navigation - depends on superfish-1.7.3-yui
        if ($('.sf-menu, .sf-mobile-menu').length) {
            $('.sf-menu, .sf-mobile-menu').superfish({delay: 0, speed: 200});
        }

        // searchform toggle animate
        var search = $('#header .searchform');
        var icon = $('#header .searchicon');

        $(icon).on('click', function()
        {
            $(this).addClass('active');
            $(search).animate({
                width: 'toggle'
            }, 500, function()
            {
                // callback function
                $(search).addClass('active');
            });
            return false;
        });

        $('body').on('click', function(e)
        {
            if (search.hasClass('active')) {
                $(search).removeClass('active');
                $(search).animate({
                    width: 'toggle'
                }, 400, function()
                {
                    // callback function
                    $(icon).removeClass('active');
                    $(search).blur();
                });
                return false;
            }
        });
        $(search).on('click', function(e)
        {
            e.stopPropagation();
        });

        // .actions article toggle
        $('a[href*="#"].toggle').each(function(e)
        {
            var toggler = $(this);
            var toggleTarget = $(toggler.attr('href'));
            var toggleSibs = $(toggleTarget.siblings('.formdrop'));
            toggler.on('click', function(e)
            {
                if (toggleTarget.is(':visible')) {
                    toggleTarget.slideUp(400);
                }
                else {
                    if (toggleSibs.is(':visible')) {
                        toggleSibs.slideUp(300);
                        toggleTarget.delay(300).slideDown(400);
                        $('html,body').animate({scrollTop: 0}, 600);
                    }
                    else {
                        toggleTarget.slideDown(400);
                        $('html,body').animate({scrollTop: 0}, 600);
                    }
                }
                e.preventDefault();
                return false;
            });
            $('html').on('click', function(e)
            {
                if (toggleTarget.is(':visible')) {
                    toggleTarget.slideUp(400);
                }
            });
            toggleTarget.on('click', function(e)
            {
                e.stopPropagation();
            });
        });

        // .up.arrow link scroll action
        $('.return-to-top').on('click', function(e)
        {
            $('html,body').animate({scrollTop: 0}, 600);
            e.preventDefault();
            return false;
        });

        // Equal Height Blocks in Rows - Published January 31, 2011 by Chris Coyier http://css-tricks.com/equal-height-blocks-in-rows/
        // these are (ruh-roh) globals. You could wrap in an immediately-Invoked Function Expression (IIFE) if you wanted to...
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = new Array();

        function setConformingHeight(el, newHeight)
        {
            // set the height to something new, but remember the original height in case things change
            el.data("originalHeight", (el.data("originalHeight") == undefined) ? (el.height()) : (el.data("originalHeight")));
            el.height(newHeight);
        }

        function getOriginalHeight(el)
        {
            // if the height has changed, send the originalHeight
            return (el.data("originalHeight") == undefined) ? (el.height()) : (el.data("originalHeight"));
        }

        function columnConform()
        {
            // find the tallest DIV in the row, and set the heights of all of the DIVs to match it.
            $('.home-blog .blog-list > li').each(function()
            {
                // "caching"
                var $el = $(this);
                var topPosition = $el.position().top;
                if (currentRowStart != topPosition) {
                    // we just came to a new row.  Set all the heights on the completed row
                    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                        setConformingHeight(rowDivs[currentDiv], currentTallest);
                    }
                    // set the variables for the new row
                    rowDivs.length = 0; // empty the array
                    currentRowStart = topPosition;
                    currentTallest = getOriginalHeight($el);
                    rowDivs.push($el);
                } else {
                    // another div on the current row.  Add it to the list and check if it's taller
                    rowDivs.push($el);
                    currentTallest = (currentTallest < getOriginalHeight($el)) ? (getOriginalHeight($el)) : (currentTallest);
                }
                // do the last row
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    setConformingHeight(rowDivs[currentDiv], currentTallest);
                }
            });
        }

        // hightlight searched terms in results
        if (typeof(hls_query) != 'undefined') {
            $(".blog-list li").each(function()
            {
                $(this).highlight(hls_query, 1, "hls");
            });
        }

        // EVENTS CALENDAR ACTIONS
        // List / Calendar View toggle
        function showCalendarView()
        {
            $('#show-calendar-view').html('List View');
            $('.events-list').hide();
            $('#calendar-view').show();
            return false;
        }
        // Calendar Overlay
        // add event content and show overlay
        $('.day.has-event').click(function ()
        {
            overlay = $('#calendar-overlay');
            overlay_content = $('.overlay-content');
            event_content = $(this).html();
            overlay_content.html(event_content);
            overlay.addClass('revealed');
            // scroll to calendar
            var target = $('#calendar'), //Get the target
                headerContent = document.querySelector('#header'),
                headerContentHeight = $(headerContent).height(),
                scrollToPosition = $(target).offset().top - headerContentHeight;
            $('html,body').animate({ 'scrollTop': scrollToPosition }, 600, function(target){
                window.location.hash;
            });
            return false;
        });
        // close overlay
        $(document).on('click', function(event)
        {
            if (!$(event.target).closest('.overlay-content').length) {
                $('#calendar-overlay').removeClass('revealed');
            }

        }); // end EVENTS CALENDAR ACTIONS

        /*-------------------------------------------------------------------------
         FORMS
         ---------------------------------------------------------------------------*/
        // show placeholders in ie - requires jquery.placeholder.min.js
        $('input,textarea').placeholder({blankSubmit: true});

        // validate forms - depends on validation plugin 1.11.1
        $('.cycle-slide-active form, .pagetitle .join-us form, #footer .join-us form').each(function()
        {
            $(this).validate();
        });
        $.extend(jQuery.validator.messages, {
            required: '*This field is required'
        });

        $('#validate').on('click', function()
        {
            $('.formholder form').each(function()
            {
                $('.formholder form').validate();
            });
            $.extend(jQuery.validator.messages, {
                required: '*This field is required'
            });
        });
        $('input[type="submit"].tw,input[type="submit"].fb').on('click', function()
        {
            $('.formholder form').unbind('submit').submit();
        });

        // MOBILE HORIZONTAL SLIDER MENU - adapted from - http://coding.smashingmagazine.com/2013/01/15/off-canvas-navigation-for-responsive-website/
        // helper functions
        var trim = function(str)
        {
            return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
        };

        var hasClass = function(el, cn)
        {
            return (' ' + el.className + ' ').indexOf(' ' + cn + ' ') !== -1;
        };

        var addClass = function(el, cn)
        {
            if (!hasClass(el, cn)) {
                el.className = (el.className === '') ? cn : el.className + ' ' + cn;
            }
        };

        var removeClass = function(el, cn)
        {
            el.className = trim((' ' + el.className + ' ').replace(' ' + cn + ' ', ' '));
        };

        var hasParent = function(el, id)
        {
            if (el) {
                do {
                    if (el.id === id) {
                        return true;
                    }
                    if (el.nodeType === 9) {
                        break;
                    }
                }
                while ((el = el.parentNode));
            }
            return false;
        };

        // normalize vendor prefixes
        var doc = document.documentElement;
        var transform_prop = window.Modernizr.prefixed('transform'),
            transition_prop = window.Modernizr.prefixed('transition'),
            transition_end = (function()
            {
                var props = {
                    'WebkitTransition': 'webkitTransitionEnd',
                    'MozTransition':    'transitionend',
                    'OTransition':      'oTransitionEnd otransitionend',
                    'msTransition':     'MSTransitionEnd',
                    'transition':       'transitionend'
                };
                return props.hasOwnProperty(transition_prop) ? props[transition_prop] : false;
            })();


            var _init = false, app = {};
            var inner = document.getElementById('inner-wrap'),
                open_btn = document.getElementById('nav-open-btn'),
                btn_class = 'opened',
                close_btn = document.getElementById('nav-close-btn'),
                nav_open = false,
                nav_class = 'js-nav';


            if (_init) {
                return;
            }
            _init = true;

            var closeNavEnd = function(e)
            {
                if (e && e.target === inner) {
                    document.removeEventListener(transition_end, closeNavEnd, false);
                }
                nav_open = false;
            };

            app.closeNav = function()
            {
                if (nav_open) {
                    // close navigation after transition or immediately
                    var duration = (transition_end && transition_prop) ? parseFloat(window.getComputedStyle(inner, '')[transition_prop + 'Duration']) : 0;
                    if (duration > 0) {
                        document.addEventListener(transition_end, closeNavEnd, false);
                    } else {
                        closeNavEnd(null);
                    }
                }
                removeClass(doc, nav_class);
                removeClass(open_btn, btn_class);
            };

            app.openNav = function()
            {
                if (nav_open) {
                    return;
                }
                addClass(doc, nav_class);
                addClass(open_btn, btn_class);
                nav_open = true;
            };

            app.toggleNav = function(e)
            {
                if (nav_open && hasClass(doc, nav_class)) {
                    app.closeNav();
                } else {
                    app.openNav();
                }
                if (e) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            };

            // open nav with main "nav" button
            document.getElementById('nav-open-btn').addEventListener('click', app.toggleNav, false);

            // close nav with main "close" button
            document.getElementById('nav-close-btn').addEventListener('click', app.toggleNav, false);

            // close nav by touching the partial off-screen content
            document.addEventListener('click', function(e)
                {
                    if (nav_open && !hasParent(e.target, 'nav')) {
                        e.stopPropagation();
                        e.preventDefault();
                        app.closeNav();
                    }
                },
                true);
            addClass(doc, 'js-ready');



        // // window resizing actions
        //
        // var widthhh = 0;
        //
        // $(window).bind('resizeEnd', function()
        // {
        //     //do something, window hasn't changed size in 500ms
        //     if (widthhh != $(window).width()) {
        //         $.stellar('refresh');
        //         columnConform();
        //         widthhh = $(window).width();
        //     }
        // });
        // $(window).resize(function()
        // {
        //     if (this.resizeTO) clearTimeout(this.resizeTO);
        //     this.resizeTO = setTimeout(function()
        //     {
        //         $(this).trigger('resizeEnd');
        //         $(window).trigger('scroll');
        //     }, 500);
        // });
        //
        // var $window = $(window);
        //
        // function checkWidth()
        // {
        //     var windowsize = $window.width();
        // }
        //
        // $(window).resize(checkWidth);



        // window resizing actions
        let resizeTimer;
        $(window).on('resize', function(e) {
            // done resizing function
            // https://css-tricks.com/snippets/jquery/done-resizing-event/
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                columnConform();
            }, 250);
        }); // end resize



        $(window).on('load', function()
        {

            // parallax scrolling with mobile support - depends on Stellar.js v0.6.0 + iScroll v5.1.1
            // adapted from http://markdalgleish.com/2012/10/mobile-parallax-with-stellar-js/
            $(function()
            {
                headerHeight = $('#header').height();
                headerOffset = -(headerHeight * 1.5);

                if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $('.cycle-slide, .pagetitle').css({'background-position-y': '0', 'background-attachment': 'scroll'});
                } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    $('.cycle-slide').css({'background-position-y': headerHeight});
                    $('.pagetitle').css({'background-position-y': '50%', 'background-attachment': 'scroll'});
                    $('.home-gallery .wide-image').css({'background-attachment': 'scroll'});
                } else {
                    $.stellar({
                        horizontalScrolling: false,
                        hideDistantElements: false,
                        responsive:true,
                        verticalOffset: headerOffset
                    });
                }
            });

            // get window width
            widthhh = $(window).width();
            // equalize columns on page load
            columnConform();

            /*-------------------------------------------------------------------------
             UTILITIES
             ---------------------------------------------------------------------------*/
            // load scripts for social widgets
            (function(doc, script)
            {
                var js,
                    fjs = doc.getElementsByTagName(script)[0],
                    add = function(url, id)
                    {
                        if (doc.getElementById(id)) {
                            return;
                        }
                        js = doc.createElement(script);
                        js.src = url;
                        id && (js.id = id);
                        fjs.parentNode.insertBefore(js, fjs);
                    };
                // Google+ button
                add('//apis.google.com/js/plusone.js');
                // AddThis SDK
                add('//s7.addthis.com/js/300/addthis_widget.js#pubid=libertyconcepts', 'js');
            }(document, 'script'));

            // open external links in new window
            $('a').each(function()
            {
                $.expr[':'].external = function(obj)
                {
                    return !obj.href.match(/^mailto\:/)
                        && (obj.hostname != location.hostname)
                        && !obj.href.match(/^http\:\/\/action.suwa.org\//)
                        && !obj.href.match(/^https\:\/\/www.suwa.org\//)
                        && !obj.href.match(/^https\:\/\/suwa.org\//)
                        && !obj.href.match(/^https\:\/\/secure2.convio.net\//)
                        && !obj.href.match(/^javascript\:/)
                };
                $('a:external').addClass('external').attr('target', '_blank');
            });
        });
    });

    // window resizing actions
    let resizeTimer;
    $(window).on('resize', function(e) {
        // done resizing function
        // https://css-tricks.com/snippets/jquery/done-resizing-event/
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            // resize functions here
            $.stellar('refresh');
            $(window).trigger('scroll');
        }, 50);
    }); // end resize

})(window.jQuery);

// AddThis config 
var addthis_config = {
    ui_delay:             500,
    data_track_clickback: true
};

// Disqus Comment Count - https://disqus.com/admin/universalcode/#comment-count
var disqus_shortname = 'suwa';
(function()
{
    var s = document.createElement('script');
    s.async = true;
    s.type = 'text/javascript';
    s.src = '//' + disqus_shortname + '.disqus.com/count.js';
    (document.getElementsByTagName('HEAD')[0] || document.getElementsByTagName('BODY')[0]).appendChild(s);
}());
